<template>
  <div>
    <!-- Back Button -->
    <v-row style="margin-bottom: 20px">
      <router-link style="margin-right: 20px" :to="{ name: 'Statistics' }">zurück</router-link>
      <v-icon size="40px">$pacman</v-icon>
      <div style="font-size: 30px; margin-left: 20px">Crawlers</div>
    </v-row>

    <!-- Search Field -->
    <v-text-field
      v-model="searchStr"
      outlined
      @click:append="search"
      label="Internetadresse, Regions Id, Bundesland oder crawler-ID eingeben"
      append-icon="mdi-magnify"
    ></v-text-field>

    <!-- Buttons -->
    <v-row>
      <v-col cols="3">
        <v-card class="dashboard-btn" @click="navigateTo({ name: 'StatisticsCrawlersToday' })">
          <v-responsive :aspect-ratio="1/1">
            <v-card-text>
              <div class="headline">
                Alle Crawler
              </div>
            </v-card-text>
          </v-responsive>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="dashboard-btn" @click="navigateTo({ name: 'StatisticsCrawlersCoverage' })">
          <v-responsive :aspect-ratio="1/1">
            <v-card-text>
              <div class="headline">
                Crawler Abdeckung<br>
                Die letzten 2 Wochen
              </div>
            </v-card-text>
          </v-responsive>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="dashboard-btn" @click="navigateTo({ name: 'StatisticsCrawlersErrorLog' })">
          <v-responsive :aspect-ratio="1/1">
            <v-card-text>
              <div class="headline">
                Error Log
              </div>
            </v-card-text>
          </v-responsive>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchStr: ''
    }
  },
  methods: {
    navigateTo (target) {
      this.$router.push(target)
    },
    search () {
      // console.log(this.searchStr)
    }
  }
}
</script>
